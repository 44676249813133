import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import Modal from 'src/components/Modal';
import ResponseErrors from 'src/components/forms/ResponseErrors';
import { Typography } from '@mui/material';

const ERROR_TIMEOUT = 5000;

interface FormErrorsProps {
  errors: Record<string, string>;
}

function FormErrors({ errors }: FormErrorsProps) {
  const intl = useIntl();

  const entries = Object.entries(errors);

  if (!entries.length) {
    return null;
  }

  return (
    <>
      <Typography variant="h5" color="text.secondary" sx={{ color: '#000', marginBottom: '16px' }}>
        {intl.formatMessage({
          id: 'form_errors',
          defaultMessage: 'Form errors',
        })}
      </Typography>
      <Alert icon={false} color="error">
        {entries
          .map(([fieldName, fieldError]) => (
            <div key={fieldName}>
              <strong>
                {intl.formatMessage({
                  id: `form.${fieldName}`,
                })}
              </strong>
              : <span>{fieldError}</span>
            </div>
          ))}
      </Alert>
    </>
  );
}

interface RegistrationErrorsModalProps {
  formErrors: any;
  responseErrors: any;
  handleClose: () => void;
  isOpen: boolean;
}

export default function RegistrationErrorsModal(
  props: RegistrationErrorsModalProps
) {
  const { formErrors, responseErrors, isOpen, handleClose } = props;

  useEffect(() => {
    if (isOpen) {
      const timeout = window.setTimeout(() => {
        handleClose();
      }, ERROR_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      width={500}
      withClose={true}
      paperStyles={{ background: '#fff' }}
    >
      <div id={'formErrors'}>
        <ResponseErrors responseError={responseErrors} />
        <FormErrors errors={formErrors} />
      </div>
    </Modal>
  );
}
