import { IntlShape, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Box from 'src/rewardis-kit/components/box';
import Typography from 'src/rewardis-kit/components/typography';
import Link from 'src/rewardis-kit/components/link';
import {
  ROUTE_PAGE_COOKIE,
  ROUTE_PAGE_PRIVACY,
  ROUTE_PAGE_TERMS,
} from 'src/constants/routes';
import { PROJECT_ALIAS } from 'src/constants/app';
import useLastClick from 'src/hooks/useLastClick';
import { SAVE_CLICK_ELEMENT_NAME } from 'src/constants/enums';
import useClasses from './get-styles';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';

const createLinks = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      id: 'footer.terms_and_conditions',
      defaultMessage: 'Terms and conditions',
    }),
    to: ROUTE_PAGE_TERMS,
  },
  {
    title: intl.formatMessage({
      id: 'footer.privacy',
      defaultMessage: 'Privacy policy',
    }),
    to: ROUTE_PAGE_PRIVACY,
  },
  {
    title: intl.formatMessage({
      id: 'footer.cookie',
      defaultMessage: 'Cookie policy',
    }),
    to: ROUTE_PAGE_COOKIE,
  },
];

const name = PROJECT_ALIAS === 'rewardis' ? 'Rewardis' : 'MySurvey';

export default function Footer() {
  const intl = useIntl();
  const { saveLast } = useLastClick();
  const styles = useClasses();

  return (
    <Box component="footer" className={styles.footerWrapper}>
      <Box className={styles.footerContainer}>
        <Box className={styles.footerContent}>
          {createLinks(intl).map(({ title, to }, key) => (
            <Link
              className={styles.footerLink}
              component={RouterLink}
              key={key}
              onClick={() => {
                saveLast(`${SAVE_CLICK_ELEMENT_NAME.FOOTER}_${title}`);
              }}
              to={to}
              underline="none"
            >
              {title}
            </Link>
          ))}
        </Box>
        <Typography
          paragraph={true}
          className={styles.footerText}
          variant={TypographyVariants.body2}
          align="center"
        >
          {intl.formatMessage({
            id: 'footer.copyright',
            defaultMessage: 'Copyright',
          })}
          {' © '}
          {new Date().getFullYear()} {name}
        </Typography>
      </Box>
    </Box>
  );
}
