import { FunctionComponent, PropsWithChildren } from 'react';

import Box from 'src/rewardis-kit/components/box';
import { ThemeProvider } from 'src/rewardis-kit/theme-provider';

import DebugPanel from 'src/components/DebugPanel';
import SwitchLanguage from 'src/components/SwitchLanguage';
import Footer from 'src/layouts/_MainFooter';
import CookieConsent from 'src/components/CookieConsent';

import { ReactComponent as Logo } from 'src/assets/logo-desktop.svg';
import { ReactComponent as LogoMobile } from 'src/assets/logo-mobile.svg';
import { useDesignParams } from 'src/hooks/useDesign';
import { GREEN_LANDING_DESIGN_ID } from 'src/constants/landings';
import useClasses from './get-styles';

const LayoutLanding: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { designId } = useDesignParams();
  const theme = GREEN_LANDING_DESIGN_ID.includes(designId)
      ? 'landingNew'
      : 'landing';

  const styles = useClasses();

  return (
    <ThemeProvider themeName={theme}>
      <DebugPanel />
      <Box className={styles.layoutWrapper}>
        <Box className={styles.layoutHeaderWrapper} component="header">
          <Box className={styles.layoutHeaderLogo}>
            <Logo />
          </Box>
          <Box className={styles.layoutHeaderLogoMobile}>
            <LogoMobile />
          </Box>
          <Box className={styles.layoutHeaderLangSwitcher}>
            <SwitchLanguage isRedesigned />
          </Box>
        </Box>
        {children}
        <Footer />
      </Box>
      <CookieConsent />
    </ThemeProvider>
  );
};

export default LayoutLanding;
