import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Theme } from '@mui/material';
import useWidthObserver from 'src/hooks/useWidthObserver';
import productMain from 'src/layouts/assets/landing-prizes/main_reg_image.png';
import productMainMobile from 'src/layouts/assets/landing-prizes/main_reg_icon.png';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

type Props = {
  isNewDesign?: boolean;
  icon?: string;
  image?: string;
};

const regularContainerStyles = (img: string) => (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: `url(${img}) no-repeat 10% 100%/50%`,
  flexWrap: 'wrap',
  paddingBottom: 6,
  ['[dir="rtl"] &']: {
    background: `url(${img}) no-repeat 100% 100%/50%`,
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: 20,
    backgroundPosition: '50% 100%',
    ['[dir="rtl"] &']: {
      backgroundPosition: '50% 100%',
      backgroundSize: '250px',
    },
    backgroundSize: '250px',
    marginTop: 1,
  },
});

const newContainerStyles = (img: string) => (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: `url(${img}) no-repeat 0% 100%/40%`,
  paddingBottom: 2,
  backgroundSize: '550px',
  ['[dir="rtl"] &']: {
    background: `url(${img}) no-repeat 100% 100%/40%`,
  },

  [theme.breakpoints.down('md')]: {
    height: '330px',
    backgroundSize: '400px',
    backgroundPosition: '50% 100%',
    ['[dir="rtl"] &']: {
      backgroundPosition: '50% 100%',
      backgroundSize: '400px',
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const Title = () => {
  const intl = useIntl();
  return (
    <Typography
      sx={(theme) => ({
        fontSize: '2em',
        color: '#FFFFFF',
        fontWeight: 700,
        '& span': {
          color: theme.palette.success.light,
          display: 'inline',
        },

        '@media (max-width: 900px)': {
          fontSize: '1.5em',
        },
      })}
    >
      {intl.formatMessage({
        id: 'registration_page.sign_up_and_geta_prize',
        defaultMessage: `Sign up and get a prize`,
      })}
    </Typography>
  );
};

const PrizeBlock: FunctionComponent<Props & PropsWithChildren> = ({
  isNewDesign,
  icon = productMainMobile,
  image = productMain,
  children,
}) => {
  const isMobile = useWidthObserver(900);
  const img = isMobile ? icon : image;
  const styles = isNewDesign
    ? newContainerStyles(img)
    : regularContainerStyles(img);

  return (
    <>
      <Container sx={styles}>
        <Box
          sx={(theme) => ({
            maxWidth: '430px',
            [theme.breakpoints.up('md')]: {
              ...(isNewDesign && {
                height: '1px',
                overflow: 'hidden',
              }),
            },
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
              margin: isNewDesign ? 0 : 'auto',
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: '290px',
            },
          })}
        >
          <Title />
        </Box>
        {!isMobile && (
          <Box
            sx={(theme) => ({
              margin: 0,
              maxWidth: '420px',
              minWidth: '360px',
              '& .form-title': { display: 'none' },
            })}
          >
            {isNewDesign && <Title />}
            {children}
          </Box>
        )}
      </Container>
      {isMobile && (
        <Box
          sx={(theme) => ({
            background: '#FFFFFF',
            paddingTop: 2,
            paddingBottom: 4,
            '& .form-title': { display: 'none' },
          })}
        >
          <Container maxWidth="xs">{children}</Container>
        </Box>
      )}
    </>
  );
};

export default PrizeBlock;
