import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import RegistrationErrorsModal from "src/components/modal/RegistrationErrorsModal";

export enum ErrorsType {
  FORM,
  RESPONSE,
}

export type ErrorsData = { type?: ErrorsType; data: Record<string, string> };

type Props = {
  errors: ErrorsData;
};

const ErrorsWrapper: FunctionComponent<Props> = ({ errors }) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const updatedErrors = useMemo(() => {
    if (errors?.type === undefined || !errors?.data) return errors;
    if (errors.type !== ErrorsType.FORM) return errors;

    return {
      some: 1,
      type: ErrorsType.FORM,
      data: Object.entries(errors.data)
          .filter(([key, _]) => key !== 'captchaV3')
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(updatedErrors.data).length) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [errors]);

  return (
    <RegistrationErrorsModal
      formErrors={updatedErrors?.type === ErrorsType.FORM ? updatedErrors.data : {}}
      responseErrors={updatedErrors?.type === ErrorsType.RESPONSE ? updatedErrors.data : {}}
      handleClose={() => setOpenModal(false)}
      isOpen={isOpenModal}
    />
  );
};

export default ErrorsWrapper;
