import * as yup from 'yup';
import { FieldHookConfig, useField } from 'formik';
import { useIntl } from 'react-intl';
import Input from 'src/rewardis-kit/components/input';
import { FieldCheckIcon } from 'src/components/forms/CheckIcon';
import { ValidationSchemaProps } from '../typings';

export const initialValue = '';

const MIN_LIMIT = 2;
const MAX_LIMIT = 128;

export const createValidationSchema = ({
  intl,
  blacklist = [],
}: ValidationSchemaProps) =>
  yup
    .string()
    .trim()
    .required(
      intl.formatMessage({
        id: 'form.validation.required.first_name',
        defaultMessage: 'This field is required',
      })
    )
    .test(
      'blacklist',
      intl.formatMessage({
        id: 'form.validation.blacklist',
        defaultMessage: 'Not allowed value',
      }),
      (value) => {
        return !blacklist.includes(value || '');
      }
    )
    .min(
      MIN_LIMIT,
      intl.formatMessage(
        {
          id: 'form.validation.min',
          defaultMessage: 'The minimum field length is {value}',
        },
        { value: MIN_LIMIT }
      )
    )
    .max(
      MAX_LIMIT,
      intl.formatMessage(
        {
          id: 'form.validation.max',
          defaultMessage: 'The maximum field length is {value}',
        },
        { value: MAX_LIMIT }
      )
    );

export function Component(props: FieldHookConfig<string>) {
  const [field, meta, helpers] = useField(props);
  const intl = useIntl();

  return (
    <Input
      {...field}
      error={!!meta.error && meta.touched}
      success={!meta.error && meta.touched}
      placeholder={intl.formatMessage({
        id: 'form.first_name',
        defaultMessage: 'First name',
      })}
      id={field.name}
      iconAfter={<FieldCheckIcon meta={meta} />}
    />
  );
}
