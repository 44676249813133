import { createPortal } from 'react-dom';
import Box from 'src/rewardis-kit/components/box';
import Button from 'src/rewardis-kit/components/button';
import {CSSProperties, MouseEventHandler, useState} from 'react';
import {
  useLocaleDispatch,
  useLocaleState,
  getProjectLocales,
  Locale,
} from 'src/providers/LocaleProvider';

import enImage from './assets/en.svg';
import esImage from './assets/es.svg';
import esArImage from './assets/es-ar.svg';
import esMxImage from './assets/es-mx.svg';
import frImage from './assets/fr.svg';
import deImage from './assets/de.svg';
import itImage from './assets/it.svg';
import arImage from './assets/ar.svg';
import idImage from './assets/id.svg';
import ptImage from './assets/pt.svg';
import ptBrImage from './assets/pt-br.svg';
import thImage from './assets/th.svg';
import vnImage from './assets/vn.svg';
import myImage from './assets/my.svg';
import phImage from './assets/ph.svg';
import * as ProfileService from 'src/services/ProfileService';
import useClasses from './get-styles';
import { mergeClasses } from '@griffel/react';
import {
  useProfileDispatch,
  useProfileState,
} from 'src/providers/ProfileProvider';
// import trImage from './assets/tr.svg';

const primaryFlags: Record<Locale, string> = {
    en: enImage,
    es: esImage,
    pt: ptImage,
    id: idImage,
    th: thImage,
    vi: vnImage,
    ms: myImage,
    tl: phImage,
    fr: frImage,
    de: deImage,
    it: itImage,
    ar: arImage,
};

const secondaryFlags: Record<string, Record<string, string>> = {
  es: {
    ar: esArImage,
    mx: esMxImage,
  },
  pt: {
    br: ptBrImage,
  },
};

const navigatorLanguage = window.navigator.language.toLowerCase();
const [primaryLanguage, secondaryLanguage] = navigatorLanguage.split('-');

const selectLocaleImage = (locale: Locale) => {
  if (locale === primaryLanguage && primaryLanguage in secondaryFlags) {
    return (
      secondaryFlags[primaryLanguage][secondaryLanguage] || primaryFlags[locale]
    );
  }

  return primaryFlags[locale];
};

const zIndex = 1000;

interface LanguageModalOverlayProps {
  onClick: () => void;
}

function LanguageModalOverlay({ onClick }: LanguageModalOverlayProps) {
  const styles = useClasses();

  return <Box className={styles.languageModalOverlay} onClick={onClick} />;
}

type ModalCoords = {
  x: number;
  y: number;
};

interface LanguageModalContentProps {
  locales: PartialRecord<Locale, string>;
  selectedLocale: Locale;
  fullWidth?: boolean;
  onClick: () => void;
  onSelect: (locale: Locale) => void;
  coords: ModalCoords;
  styles?: CSSProperties;
}

function LanguageModalContent({
  locales,
  selectedLocale,
  fullWidth,
  onClick,
  onSelect,
  styles: additionalStyles,
  coords,
}: LanguageModalContentProps) {
  const styles = useClasses();
  const selectedClass = selectedLocale && styles.modalButtonWithIsSelected;
  const fullWidthClass = fullWidth
    ? styles.languageModalContentMainWidthFull
    : styles.languageModalContentMainWidthoutFull;

  return (
    <Box
      className={styles.languageModalContentWrapper}
      style={{
        top: coords.y + 16 + 'px',
        right: coords.x + 'px',
        ...additionalStyles,
      }}
      onClick={onClick}
    >
      <Box
        component="main"
        className={mergeClasses(
          styles.languageModalContentMain,
          fullWidthClass
        )}
      >
        <Box className={styles.languageModalContentButtons}>
          {Object.entries(locales).map(([locale, title]) => (
            <Button
              key={locale}
              tabIndex={1}
              onClick={() => onSelect(locale as Locale)}
              className={mergeClasses(styles.modalButton, selectedClass)}
            >
              <Box
                component="img"
                src={selectLocaleImage(locale as Locale)}
                className={styles.languageModalContentImage}
              />
              <Box sx={{ marginRight: 1, marginLeft: 1 }}>{title}</Box>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

interface LanguageModalProps {
  locale: Locale;
  styles?: CSSProperties;
  locales: PartialRecord<Locale, string>;
  fullWidth?: boolean;
  handleClose: () => void;
  handleSelect: (locale: Locale) => void;
  coords?: ModalCoords;
}

function LanguageModal({
  locale,
  locales,
  fullWidth,
  handleClose,
  handleSelect,
  coords,
  styles,
}: LanguageModalProps) {
  if (!coords) {
    return null;
  }

  return createPortal(
    <>
      <LanguageModalOverlay onClick={handleClose} />
      <LanguageModalContent
        styles={styles}
        coords={coords}
        fullWidth={fullWidth}
        onClick={handleClose}
        onSelect={handleSelect}
        locales={locales}
        selectedLocale={locale}
      />
    </>,
    document.body
  );
}

interface LanguageButtonProps {
  isOpen: boolean;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  locale: Locale;
  isRedesigned?: boolean;
}

function LanguageButton({
  isOpen,
  locale,
  handleClick,
  isRedesigned,
}: LanguageButtonProps) {
  const styles = useClasses();
  const redesignClass = isRedesigned
    ? styles.buttonWithRedesign
    : styles.buttonWithoutRedesign;
  return (
    <Button
      tabIndex={isOpen ? 1 : 0}
      onClick={handleClick}
      className={mergeClasses(styles.button, redesignClass)}
    >
      {locale.slice(0, 1).toLocaleUpperCase() + locale.slice(1)}
      <div className={styles.arrow} />
    </Button>
  );
}

interface SwitchLanguageProps {
  fullWidth?: boolean;
  isRedesigned?: boolean;
}

export default function SwitchLanguage({
  fullWidth,
  isRedesigned,
}: SwitchLanguageProps) {
  const locale = useLocaleState();
  const locales = getProjectLocales();
  const { setLocale } = useLocaleDispatch();
  const [modalCoords, setCoords] = useState<{ x: number; y: number }>();
  const { profile } = useProfileState();
  const { setProfileField } = useProfileDispatch();

  const changeProfileLanguage = async (language: string) => {
    if (profile) {
      await ProfileService.updateLanguage({ language });
      setProfileField('language', language);
    }
  };

  const handleSelect = async (locale: Locale) => {
    setLocale(locale);
    await changeProfileLanguage(locale);
    setCoords(undefined);
  };

  if (Object.entries(locales).length < 2) {
    return null;
  }

  return (
    <>
      <LanguageButton
        locale={locale}
        isOpen={!!modalCoords}
        isRedesigned={isRedesigned}
        handleClick={(e) => {
          const bodyRect = document.body.getBoundingClientRect();
          const targetRect = (e.target as HTMLElement).getBoundingClientRect();

          const x = bodyRect.width - targetRect.right;
          setCoords({
            x: x > 125 ? x : 125,
            y: targetRect.bottom,
          });
        }}
      />
      <LanguageModal
        styles={{ transform: 'translate(50%, 0)' }}
        locale={locale}
        locales={locales}
        coords={modalCoords}
        fullWidth={fullWidth}
        handleSelect={handleSelect}
        handleClose={() => setCoords(undefined)}
      />
    </>
  );
}
