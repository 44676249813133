import * as yup from 'yup';
import { memo, useCallback } from 'react';
import { FieldHookConfig, useField } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import Box from 'src/rewardis-kit/components/box';
import GenderControlField from './GenderControlField';
import useClasses from './get-styles';

const GENDER_MALE = 1;
const GENDER_FEMALE = 2;

export const initialValue = null;

interface ValidationSchemaProps {
  intl: IntlShape;
}

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .mixed()
    .nullable(true)
    .oneOf([1, 2], (values) => {
      const value = parseInt(values.value, 10);
      if (((values as any).resolved || []).indexOf(value) == -1) {
        return intl.formatMessage({
          id: 'form.validation.required.gender',
          defaultMessage: 'This field is required',
        });
      }
    })
    .required(
      intl.formatMessage({
        id: 'form.validation.required.gender',
        defaultMessage: 'This field is required',
      })
    );

const GenderField = (props: FieldHookConfig<number>) => {
  const intl = useIntl();
  const [_, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  const styles = useClasses();

  const handleGenderMaleClick = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue(GENDER_MALE);
  }, [helpers]);

  const handleGenderFemaleClick = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue(GENDER_FEMALE);
  }, [helpers]);

  return (
    <Box className={styles.control}>
      <Box className={styles.label} id={'genderLabel'}>
        {intl.formatMessage({
          id: 'form.gender',
          defaultMessage: 'Gender',
        })}
      </Box>
      <Box className={styles.buttonsContainer}>
        <GenderControlField
          type="male"
          hasError={hasError}
          handleClick={handleGenderMaleClick}
          isSelected={meta.value == GENDER_MALE}
          label={intl.formatMessage({
            id: 'form.male',
            defaultMessage: 'Male',
          })}
          id="gender_button-male"
        />
        <GenderControlField
          type="female"
          hasError={hasError}
          handleClick={handleGenderFemaleClick}
          isSelected={meta.value == GENDER_FEMALE}
          label={intl.formatMessage({
            id: 'form.female',
            defaultMessage: 'Female',
          })}
          id="gender_button-female"
        />
      </Box>
    </Box>
  );
};

export const Component = memo(GenderField);
