import { useIntl } from 'react-intl';
import { formatDistanceToNowStrict } from 'date-fns';
import Box from 'src/rewardis-kit/components/box';
import Typography from 'src/rewardis-kit/components/typography';
import balanceImage from 'src/assets/balance-green.svg';
import './sprite-16.css';
import useClasses from './get-styles';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';
import { mergeClasses } from '@griffel/react';

interface EarningUserProps {
  onClick: () => void;
  userEarning: UserEarning;
  invertColors?: boolean;
}

const ITEM_WIDTH = 165;
const ITEM_HEIGHT = 42;

const Flag: React.FunctionComponent<{
  name: string;
}> = ({ name }) => {
  return <div className={`sprite-16 sprite-16-${name}`} />;
};

const getDistance = (createdAt: number) => {
  try {
    const startDate = new Date(createdAt * 1000);

    return formatDistanceToNowStrict(startDate);
  } catch (err) {
    console.error(err);

    return '';
  }
};

export default function EarningUserItem({
  onClick,
  userEarning,
  invertColors,
}: EarningUserProps) {
  const intl = useIntl();
  const distanceInWords = getDistance(userEarning.created_at);
  const styles = useClasses();

  return (
    <Box
      onClick={onClick}
      className={mergeClasses(
        styles.earningUserItemContainer,
        invertColors && styles.withInvertEarningUserItemContainer
      )}
      style={{
        flexShrink: ITEM_WIDTH + 'px',
        height: ITEM_HEIGHT + 'px',
      }}
    >
      <Box className={styles.earningUserItemLeft}>
        <Box
          className={styles.earningUserItemLeftIcon}
          style={{
            background: `url(${userEarning.image}) center/cover`,
          }}
        />
        <Box className={styles.earningUserItemLeftFlagWrapper}>
          <Flag name={userEarning.geo} />
        </Box>
      </Box>

      <Box className={styles.earningUserItemRight}>
        <Typography
          noWrap
          variant={TypographyVariants.body2}
          className={mergeClasses(
            styles.earningUserItemRightName,
            invertColors && styles.withInvertEarningUserItemRightName
          )}
        >
          {userEarning.name}
        </Typography>
        {distanceInWords ? (
          <Typography
            noWrap
            variant={TypographyVariants.body2}
            className={mergeClasses(
              styles.earningUserItemRightTimeAgo,
              invertColors && styles.withInvertEarningUserItemRightTimeAgo
            )}
          >
            {intl.formatMessage(
              {
                id: 'time_ago',
                defaultMessage: '{time} ago',
              },
              { time: distanceInWords }
            )}
          </Typography>
        ) : null}
      </Box>
      <Box
        className={mergeClasses(
          styles.earningUserItemAmountBox,
          invertColors && styles.withInvertErningUserItemAmountBox
        )}
      >
        <Typography
          variant={TypographyVariants.body2}
          className={mergeClasses(
            styles.earningUserItemAmount,
            invertColors && styles.withInvertEarningUserItemAmount
          )}
        >
          {userEarning.amount}
        </Typography>
        <img src={balanceImage} width={12} height={10} />
      </Box>
    </Box>
  );
}
