import Button from 'src/rewardis-kit/components/button';

import { ReactComponent as GoogleIcon } from 'src/assets/icons/google.svg';
import { useIntl } from 'react-intl';
import { FunctionComponent, memo } from 'react';
import useClasses from 'src/components/buttons/get-styles';
import { mergeClasses } from '@griffel/react';

const GoogleButtonStandard: FunctionComponent<{
  onClick: () => void;
  isMobile?: boolean;
}> = ({ onClick, isMobile = false }) => {
  const intl = useIntl();
  const styles = useClasses();

  return (
    <Button
      className={mergeClasses(
        styles.googleButtonStandard,
        isMobile ? styles.withIsMobile : styles.withIsNotMobile
      )}
      type={'button'}
      size={'lg'}
      variant="contained"
      iconBefore={<GoogleIcon />}
      onClick={onClick}
      id="signup_google"
    >
      {intl.formatMessage(
        {
          id: 'sign_in_with',
          defaultMessage: 'Sign in with Google',
        },
        {
          name: 'Google',
        }
      )}
    </Button>
  );
};

export default memo(GoogleButtonStandard);
