import { useEffect, useMemo, useRef, useState } from 'react';

import InApp from './detect';
import { intentLink } from './intentLink';
import {
  IBrowsersForIntent,
  INeedBrowsersForIntent,
  TYPE_BROWSERS_INTENT,
} from './types';
import useRedirectUrl from 'src/components/InAppOverlay/useRedirectUrl';
import { getChangeBrowserLinkFromIOS } from 'src/hooks/useBrowserChanger/inApp/utils';
import { BROWSER_NAME } from 'src/components/InAppOverlay/browsers';

export interface IInAppOverlayProps {
  browsersForIntent?: IBrowsersForIntent;
}

const InAppOverlay = ({ browsersForIntent }: IInAppOverlayProps) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const isOverlayClicked = useRef(false);
  const useBrowsersForIntent: INeedBrowsersForIntent = {
    miui: {
      isEnabled: browsersForIntent?.miui,
      type: TYPE_BROWSERS_INTENT.MiuiBrowser,
    },
    puffin: {
      isEnabled: browsersForIntent?.puffin,
      type: TYPE_BROWSERS_INTENT.Puffin,
    },
  };
  const getRedirectUrl = useRedirectUrl();
  const inApp = useMemo(() => {
    const ua = window.navigator.userAgent;

    return new InApp(ua, useBrowsersForIntent);
  }, []);

  const detectInApp = () => {
    if (inApp.isInApp) {
      setShowOverlay(true);
    }
  };

  const redirectToExitUrl = () => {
    const redirectUrl = getRedirectUrl();

    if (redirectUrl) {
      document.location.replace(redirectUrl);
    } else {
      setShowOverlay(false);
    }
  };

  const onVisibilitychange = () => {
    if (!document.hidden) {
      document.removeEventListener('visibilitychange', onVisibilitychange);
      redirectToExitUrl();
    }
  };

  const handleInApp = () => {
    if (showOverlay) {
      const url = new URL(window.location.href);
      url.searchParams.append('sub_id_7', inApp.browser);

      const intent =
        BROWSER_NAME.SAFARI === (inApp.browser as BROWSER_NAME)
          ? getChangeBrowserLinkFromIOS(url)
          : intentLink(url.href);

      if (isOverlayClicked.current) {
        redirectToExitUrl();
      } else {
        document.location.href = intent;
        document.addEventListener('visibilitychange', onVisibilitychange);
      }
    }
  };

  useEffect(detectInApp, []);

  if (!showOverlay) return null;

  const prepareOnClick = () => {
    handleInApp();
    isOverlayClicked.current = true;
  };

  if (!inApp.isInApp && inApp.browser === BROWSER_NAME.SAFARI) {
    return null;
  }

  return (
    <div
      onClick={prepareOnClick}
      onKeyDown={prepareOnClick}
      aria-label="in-app-overlay"
      role="button"
      tabIndex={0}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 99999,
      }}
    />
  );
};

export default InAppOverlay;
