import { IntlShape } from 'react-intl';
import { MAX_AGE_LIMIT, MIN_AGE_LIMIT } from 'src/lib/date';
import * as yup from 'yup';

export const requiredMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'form.validation.required.birth_date',
    defaultMessage: 'This field is required',
  });

export const ageLimitMessage = (intl: IntlShape) =>
  intl.formatMessage(
    {
      id: 'form.validation.age_limit',
      defaultMessage: `Should be greater than {minAgeLimit} years and less than {maxAgeLimit} years old`,
    },
    {
      minAgeLimit: MIN_AGE_LIMIT,
      maxAgeLimit: MAX_AGE_LIMIT,
    }
  );
export const isDateWithinAgeLimits: yup.TestFunction<Date | undefined> = (
  date
): boolean => {
  if (!date) return false;

  const currentDate = new Date();
  const oneHundredYearsAgo = new Date(
    currentDate.getFullYear() - 100,
    currentDate.getMonth(),
    currentDate.getDate() - 1
  );
  const ageLimitDate = new Date(
    currentDate.getFullYear() - MIN_AGE_LIMIT,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return date > oneHundredYearsAgo && date <= ageLimitDate;
};
